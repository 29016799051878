import React, { useState, useEffect, useContext } from "react";
import * as Constants from "../../constants/global";
import axios from "axios";
import { connect } from "react-redux";
//import { AppContext } from '../../context/AppState';
import Progress from "../util/Progress";
import { ReactComponent as Minus } from "../../assets/icon/minus.svg";
import { ReactComponent as Plus } from "../../assets/icon/plus.svg";
import descuento from "../../assets/img/descuento.png";
import noimage from "../../assets/img/noimage.png";
import DetalleComboModal from "../modal/DetalleComboModal";
import CantidadInventario from "../ComponentesAuxiliares/CantidadInventario";
import Semantic from "../../Semantic-UI-Alert.js";
import SemanticCss from "../../Semantic-UI-Alert.css";
import { traerProductos, traerProductosTodos } from "../../actions";
import $ from "jquery";

const Producto = (props) => {
  const [carrito, setCarrito] = useState();
  const [loader, setLoader] = React.useState(false);
  const [productos, setProductos] = useState();
  const [combos, setCombos] = useState([]);
  const [imagenBanner, setImagenBanner] = useState("B1.jpg");
  const [detalleCombo, setDetalleCombo] = useState([]);

  // let imagenes = ['COMBOS.jpg', 'INFANTILES.jpg', 'PASABOCAS.jpg', 'PONQUES.jpg'],
  let cont = 0;

  // const [cantidad, setCantidad] = useState();
  let sucursal = localStorage.getItem("sucursal");
  let codCliente = localStorage.getItem("codCliente");
  let nombreCliente = localStorage.getItem("nombrecliente");
  let cupo = localStorage.getItem("cupo");
  let tipocredito = localStorage.getItem("tipocredito");
  let usuario = localStorage.getItem("usuario");

  const loadBanners = async () => {
    let url = Constants.URL_BASE + Constants.WSBANNERS;

    axios.get(url).then((res) => {
      efectosBanners(res.data);
    });
  };

  const efectosBanners = (imagenes) => {
    if (props.tipo == 1) {
      let contenedor = document.querySelector(".banner-imagenes");

      let atras = contenedor.querySelector(".atras"),
        adelante = contenedor.querySelector(".adelante"),
        img = contenedor.querySelector("img");

      carrusel(imagenes, contenedor, atras, adelante, img);

      setInterval(() => {
        if (cont < imagenes.length - 1) {
          setImagenBanner(imagenes[cont + 1]["ruta"]);
          cont++;
        } else {
          setImagenBanner(imagenes[0]["ruta"]);
          cont = 0;
        }
      }, 5000);
    }
  };

  // TRAE TODOS LOS PRODUCTOS
  const loadProductos = async (tipo) => {
    let urlProductosTodos =
      Constants.URL_BASE + Constants.WSPRODUCTOTODOS + "?usuario=" + usuario;

    let urlConfiguracion =
      Constants.URL_BASE +
      Constants.WSPRODUCTO +
      "?items=" +
      "20" +
      "&usuario=" +
      usuario;

    if (sucursal) {
      urlProductosTodos += "&sucursal=" + sucursal;
      urlConfiguracion += "&sucursal=" + sucursal;
    }

    if (codCliente) {
      urlProductosTodos += "&codCliente=" + codCliente;
      urlConfiguracion += "&codCliente=" + codCliente;
    }

    if (props.search) {
      urlProductosTodos += "&search=" + props.search;
      urlConfiguracion += "&search=" + props.search;
    }

    if (tipo == 1) {
      if (props.productos) {
        if (props.cantidadProductos.now < props.cantidadProductos.next) {
          urlProductosTodos += "&page=" + props.cantidadProductos.next;
          urlConfiguracion += "&page=" + props.cantidadProductos.next;
        }
      }
    }
    console.log(urlConfiguracion);
    props.traerProductos(urlConfiguracion);
    props.traerProductosTodos(urlProductosTodos);

    // if (tipo == 1) {
    //   if (props.productos) {
    //     if (props.productos.result) {
    //       let combine = productos.result.concat(array.result);
    //       array.result = combine;
    //     }
    //   }
    // }

    // setProductos(array);
    setLoader(false);

    props.handleCantidad();

    cargarCarrito();
  };

  const cargarCarrito = () => {
    setCarrito(JSON.parse(localStorage.getItem(codCliente)) || {});
  };

  const cargarMas = () => {
    loadProductos(1);
  };

  // AUMENTA LA CANTTIDAD
  const handleMas = (item, cantidad = 0) => {
    if (carrito[item.codigo]) {
      if (item.cantidadInventario <= carrito[item.codigo]["cantidad"]) {
        mostrarAlertaInventario();
      } else {
        carrito[item.codigo]["cantidad"] += 1;
        almacenarRecargar(carrito);
      }
    } else {
      if (item.cantidadInventario === 0) {
        mostrarAlertaInventario();
      } else {
        carrito[item.codigo] = {
          nombre: item.nombre,
          precio: parseFloat(item.precio),
          preciopvp: item.preciopvp,
          pesobruto: item.pesobruto,
          cantant: item.cantant,
          iva: parseInt(item.iva),
          cantidad: cantidad == 0 ? 1 : cantidad,
        };
        almacenarRecargar(carrito);
      }
    }
  };

  const mostrarAlertaInventario = () => {
    $.uiAlert({
      textHead: "Alerta", // header
      text: "La cantidad solicitada supera el inventario.", // Text
      bgcolor: "#DB2828", // background-color
      textcolor: "#fff", // color
      position: "bottom-right", // position . top And bottom ||  left / center / right
      icon: "remove circle", // icon in semantic-UI
      time: 3, // time
    });
  };

  const almacenarRecargar = () => {
    localStorage.setItem(codCliente, JSON.stringify(carrito));
    setCarrito(carrito);
    loadProductos(0);
  };

  const handleMenos = (item) => {
    if (carrito[item.codigo]) {
      if (carrito[item.codigo]["cantidad"] == 1) {
        delete carrito[item.codigo];
      } else {
        carrito[item.codigo]["cantidad"] -= 1;
      }
    }
    setCarrito(carrito);
    localStorage.setItem(codCliente, JSON.stringify(carrito));
    loadProductos(0);
  };

  const handleChangeCantidad = (item, valor) => {
    if (valor <= 0) {
      delete carrito[item.codigo];
    } else {
      if (carrito[item.codigo]) {
        if (item.cantidadInventario < valor) {
          mostrarAlertaInventario();
        } else {
          carrito[item.codigo]["cantidad"] = parseInt(valor);
          almacenarRecargar(carrito);
        }
      } else {
        if (item.cantidadInventario === 0) {
          mostrarAlertaInventario();
        } else {
          carrito[item.codigo] = {
            nombre: item.nombre,
            precio: item.precio,
            preciopvp: item.preciopvp,
            pesobruto: item.pesobruto,
            cantant: item.cantant,
            iva: parseInt(item.iva),
            cantidad: parseInt(valor),
          };
          almacenarRecargar(carrito);
        }
      }
    }
    // setCarrito(carrito);
    // localStorage.setItem(codCliente, JSON.stringify(carrito));
    // loadProductos();
  };

  const cargarCombos = () => {
    let url =
      Constants.URL_BASE +
      Constants.WSPRODUCTOSCOMBO +
      "?codCliente=" +
      codCliente +
      "&sucursal=" +
      sucursal;

    axios.get(url).then((res) => {
      setCombos(res.data);
    });
  };

  const handleClickCombo = (combo) => {
    if (combo.categoria == "COMBOS") {
      let infoDetalle;

      combos
        .filter((o) => o.codigopadre === combo.codigo)
        .map((item, i) => {
          let url =
            Constants.URL_BASE + Constants.WSPRODUCTO + "?items=" + "20";

          if (sucursal) {
            url += "&sucursal=" + sucursal;
          }

          if (codCliente) {
            url += "&codCliente=" + codCliente;
          }

          url += "&search=" + item.codigohijo;

          axios.get(url).then((res) => {
            let array = res.data;

            if (array.result.length > 0) {
              array.result[0].cantidad = item.cantidad;
            }

            if (infoDetalle) {
              if (infoDetalle.result) {
                let combine = infoDetalle.result.concat(array.result);
                array.result = combine;
              }
            }

            infoDetalle = array;

            setDetalleCombo(infoDetalle);
          });
        });

      document.querySelector(".modal-combo").classList.toggle("show-modal");
    }
  };

  // SE EJECUTA CUANDO CAMBIA CATEGORIA O BUSQUEDA
  // useEffect(() => {
  //   console.log("CARRITO");
  //   loadBanners();
  //   setLoader(true);
  //   /*setCarrito(JSON.parse(localStorage.getItem(codCliente)) || {});*/
  //   cargarCombos();
  // }, [carrito]);

  // useEffect(() => {
  //   if (props.descuentos) {
  //     loadProductos(0);
  //   }
  //   setLoader(false);
  // }, props.descuentos);

  useEffect(() => {
    setLoader(true);
    loadProductos(0);
  }, [props.search]);

  useEffect(() => {
    setLoader(true);
    loadBanners();
    loadProductos(0);
    cargarCombos();
  }, []);

  const carrusel = (imagenes, contenedor, atras, adelante, img) => {
    contenedor.addEventListener("click", (e) => {
      let tgt = e.target;

      if (tgt == atras) {
        if (cont > 0) {
          setImagenBanner(imagenes[cont - 1]["ruta"]);
          cont--;
        } else {
          setImagenBanner(imagenes[imagenes.length - 1]["ruta"]);
          cont = imagenes.length - 1;
        }
      } else if (tgt == adelante) {
        if (cont < imagenes.length - 1) {
          setImagenBanner(imagenes[cont + 1]["ruta"]);
          cont++;
        } else {
          setImagenBanner(imagenes[0]["ruta"]);
          cont = 0;
        }
      }
    });
  };

  // VISTA
  return (
    <div className="main">
      <div className="banner-ramo">
        {props.tipo == 1 ? (
          <div className="banner-imagenes">
            <div className="atras botones">&#60;</div>

            <div className="adelante botones">&#62;</div>

            <img
              src={require(`../../assets/banners/${imagenBanner}`)}
              id="imagen"
            ></img>
          </div>
        ) : null}
      </div>
      <div className="pedido-list padding-banner">
        <div className="pedido-list">
          {loader ? (
            <Progress color={"#007daf"} />
          ) : (
            <React.Fragment>
              {props.productos ? (
                <React.Fragment>
                  {props.tipo == 2 ? (
                    <table className="table-productos">
                      <thead>
                        <tr>
                          <th colSpan={"2"} style={{ color: "black" }}>
                            {nombreCliente}
                          </th>
                          <th colSpan={"2"} style={{ color: "black" }}>
                            CUPO: {Constants.copFormat(cupo)}
                          </th>
                          <th colSpan={"1"} style={{ color: "black" }}>
                            TIPO VENTA: {tipocredito}
                          </th>
                          <th colSpan={"2"}>Promedio Venta</th>
                          <th colSpan={"2"}>Sugerido</th>
                          <th colSpan={"4"}>&nbsp;</th>
                        </tr>
                        <tr>
                          <th className={"color-azul"}>Imagen</th>
                          <th className={"color-azul"}>Precio VTA</th>
                          <th className={"color-azul"}>Precio PVP</th>
                          <th className={"color-azul"}>SKU</th>
                          <th className={"color-azul"}>Producto</th>
                          <th className={"color-azul"}>Und</th>
                          <th className={"color-azul"}>Valor</th>
                          <th className={"color-naranja"}>Und</th>
                          <th className={"color-naranja"}>Valor</th>
                          {/* <th className={"color-verde"}>Unidades</th>
                                                        <th className={"color-verde"}>Valor Sin Iva</th>
                                                        <th className={"color-verde"}>Iva</th> */}
                          {/* <th className={"color-verde"}>Descuento</th> */}
                          <th className={"color-verde"}>Inventario</th>
                          <th className={"color-verde"}>Rentabilidad</th>
                          <th className={"color-verde"}>Valor Total</th>
                          <th className={"color-verde"}>Acción</th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.productos.map((item, i) => (
                          <tr key={item.codigo}>
                            <td>
                              <img
                                width="150"
                                className={
                                  item.categoria == "COMBOS"
                                    ? "img-product cursor"
                                    : "img-product"
                                }
                                onClick={() => handleClickCombo(item)}
                                style={
                                  item.categoria == "COMBOS"
                                    ? { cursor: "pointer" }
                                    : {}
                                }
                                src={
                                  Constants.URL_IMG_PRODUCTOS +
                                  item.codigo.toString().trim() +
                                  ".jpg"
                                }
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = noimage;
                                }}
                              ></img>
                              {props.descuentos ? (
                                props.descuentos.filter(
                                  (o) => o.codProducto === item.codigo
                                ).length > 0 ? (
                                  <img
                                    src={descuento}
                                    width={50}
                                    className="img-descuento"
                                  ></img>
                                ) : null
                              ) : null}
                            </td>
                            <td>
                              {Constants.copFormat(
                                Math.round(
                                  item.precio *
                                    (1 + (item.iva ? item.iva : 0) / 100)
                                )
                              )}
                            </td>
                            <td>{Constants.copFormat(item.preciopvp)}</td>
                            <td>{item.codigo}</td>
                            <td>{item.nombre}</td>
                            <td>{item.cantant}</td>
                            <td>{Constants.copFormat(item.valorant)}</td>
                            <td>{item.cantsug}</td>
                            <td>
                              {Constants.copFormat(
                                item.cantsug *
                                  (item.precio *
                                    (1 + (item.iva ? item.iva : 0) / 100))
                              )}
                            </td>
                            <td>{item.cantidadInventario}</td>
                            {carrito && carrito[item.codigo] ? (
                              <React.Fragment>
                                {/* <td>{carrito[item.codigo].cantidad}</td>
                                                                    <td>{ Constants.copFormat( carrito[item.codigo].cantidad * item.precio - (carrito[item.codigo].descuentoAplicado ? carrito[item.codigo].descuentoAplicado : 0) ) }</td>
                                                                    <td>{ Constants.copFormat( (carrito[item.codigo].cantidad * item.precio - (carrito[item.codigo].descuentoAplicado ? carrito[item.codigo].descuentoAplicado : 0) ) * ( ((item.iva ? item.iva : 0) / 100)) ) }</td> */}
                                {/* <td>{ Constants.copFormat( (carrito[item.codigo].descuentoAplicado ? carrito[item.codigo].descuentoAplicado : 0) ) }</td> */}
                                <td>
                                  {Constants.copFormat(
                                    carrito[item.codigo].cantidad *
                                      item.preciopvp -
                                      carrito[item.codigo].cantidad *
                                        (item.precio *
                                          (1 + (item.iva ? item.iva : 0) / 100))
                                      ? carrito[item.codigo].cantidad *
                                          item.preciopvp -
                                          carrito[item.codigo].cantidad *
                                            (item.precio *
                                              (1 +
                                                (item.iva ? item.iva : 0) /
                                                  100))
                                      : 0
                                  )}
                                </td>
                                <td>
                                  {Constants.copFormat(
                                    (carrito[item.codigo].cantidad *
                                      item.precio -
                                      (carrito[item.codigo].descuentoAplicado
                                        ? carrito[item.codigo].descuentoAplicado
                                        : 0)) *
                                      (1 + (item.iva ? item.iva : 0) / 100)
                                  )}
                                </td>
                                <td>
                                  <div className="action">
                                    <div className="controls">
                                      <button
                                        role="button"
                                        onClick={() => handleMenos(item)}
                                      >
                                        {/* <img src={minus} alt="icon minus" width="15px" height="15px"></img> */}
                                        <Minus
                                          className="svg-una-capa"
                                          style={{
                                            width: "15px",
                                            height: "15px",
                                          }}
                                        />
                                      </button>
                                      <input
                                        type="number"
                                        onChange={(e) =>
                                          handleChangeCantidad(
                                            item,
                                            e.target.value
                                          )
                                        }
                                        value={carrito[item.codigo].cantidad}
                                      />
                                      <button
                                        role="button"
                                        onClick={() => handleMas(item)}
                                      >
                                        {/* <img src={plus} alt="icon plus" width="15px" height="15px"></img> */}
                                        <Plus
                                          className="svg-una-capa"
                                          style={{
                                            width: "15px",
                                            height: "15px",
                                          }}
                                        />
                                      </button>
                                    </div>
                                  </div>
                                </td>
                              </React.Fragment>
                            ) : (
                              <React.Fragment>
                                {/* <td>0</td>
                                                                    <td>{ Constants.copFormat( 0 ) }</td>
                                                                    <td>{ Constants.copFormat( 0 ) }</td> */}
                                {/* <td>{ Constants.copFormat( 0 ) }</td> */}
                                <td>{Constants.copFormat(0)}</td>
                                <td>{Constants.copFormat(0)}</td>
                                <td>
                                  <div className="action">
                                    <button
                                      className="agregar"
                                      onClick={() =>
                                        handleMas(item, item.cantsug)
                                      }
                                    >
                                      Agregar
                                    </button>
                                  </div>
                                </td>
                              </React.Fragment>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    // <div className="product-list">
                    //     {productos.result.map((item, i) => (
                    //         <div className="item" key={i}>
                    //             <div>
                    //                 <p className="nombre"><b>{item.nombre}</b></p>
                    //                 <p className="codigo"><b>Codigo:</b> {item.codigo}
                    //                 {/* - <b>EAN:</b> {item.ean} */}
                    //                 </p>
                    //                 <p className="precio"> { Constants.copFormat(item.precio * (1 + ((item.iva ? item.iva : 0) / 100))) }
                    //                     {/* {new Intl.NumberFormat("es-CO", {
                    //                         style: "currency",
                    //                         currency: "COP"
                    //                     }).format(item.precio * (1 + ((item.iva ? item.iva : 0) / 100)))} */}
                    //                 </p>
                    //             </div>
                    //             <div className="action">
                    //                 { carrito[item.codigo] ? (
                    //                     <div className="controls">
                    //                         <button role="button" onClick={() => handleMenos(item)}>
                    //                             {/* <img src={minus} alt="icon minus" width="15px" height="15px"></img> */}
                    //                             <Minus className="svg-una-capa" style={{width:"15px", height:"15px"}}/>
                    //                         </button>
                    //                         <input type="number"
                    //                             onChange={e => handleChangeCantidad(item, e.target.value)}
                    //                             value={carrito[item.codigo].cantidad}/>
                    //                         <button role="button" onClick={() => handleMas(item)}>
                    //                             {/* <img src={plus} alt="icon plus" width="15px" height="15px"></img> */}
                    //                             <Plus className="svg-una-capa" style={{width:"15px", height:"15px"}}/>
                    //                         </button>
                    //                     </div>
                    //                 ) : (
                    //                     <button className="agregar" onClick={() => handleMas(item)}>Agregar</button>
                    //                 )}
                    //             </div>
                    //         </div>
                    //     ))}
                    // </div>
                    <div className="product-card">
                      {props.productos.map((item, i) => (
                        <div className="card" key={i}>
                          <img
                            width="150"
                            className="img-product"
                            src={
                              Constants.URL_IMG_PRODUCTOS +
                              item.codigo.toString().trim() +
                              ".jpg"
                            }
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = noimage;
                            }}
                          ></img>
                          <p className="nombre">
                            <b>{item.nombre}</b>
                          </p>
                          <p className="codigo">
                            <b>Codigo:</b> {item.codigo}
                          </p>
                          {/* <p className="codigo"><b>EAN:</b> {item.ean}</p> */}
                          {/* <p className="precio"> {Constants.copFormat(item.precio * (1 + ((item.iva ? item.iva : 0) / 100)))}
                                                        </p> */}
                          {/* <div className="action">
                                                            { carrito[item.codigo] ? (
                                                                <div className="controls">
                                                                    <button role="button" onClick={() => handleMenos(item)}>
                                                                        <Minus className="svg-una-capa" style={{width:"15px", height:"15px"}}/>
                                                                    </button>
                                                                    <input type="number" 
                                                                        onChange={e => handleChangeCantidad(item, e.target.value)}
                                                                        value={carrito[item.codigo].cantidad}/>
                                                                    <button role="button" onClick={() => handleMas(item)}>
                                                                        <Plus className="svg-una-capa" style={{width:"15px", height:"15px"}}/>
                                                                    </button>
                                                                </div>
                                                            ) : (
                                                                <button className="agregar" onClick={() => handleMas(item)}>Agregar</button>
                                                            )}
                                                        </div> */}
                        </div>
                      ))}
                    </div>
                  )}

                  <div className="load-mas">
                    {props.cantidadProductos.now <
                    props.cantidadProductos.next ? (
                      <button onClick={() => cargarMas()}>Cargar mas</button>
                    ) : null}
                  </div>
                </React.Fragment>
              ) : null}
            </React.Fragment>
          )}
        </div>

        <DetalleComboModal
          detalleCombo={detalleCombo.result}
          noimage={noimage}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    cantidadProductos: state.quantityProducts,
    productos: state.productos.lista,
    descuentos: Object.values(state.descuentos),
  };
};

export default connect(mapStateToProps, {
  traerProductos,
  traerProductosTodos,
})(Producto);
